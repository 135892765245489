import axios from 'axios'

export default class LocationService {
  static params = {
    locale: 'fr',
    mode: 'geoloc',
    token: process.env.VUE_APP_ALGOLIA_GATEWAY_TOKEN,
    rankingInfo: true,
    countries: 'fr,re,gp,mq',
    filter: 'customLists.pandc:yes',
    hitsPerPage: 5,
  }

  static searchLocations = async query => {
    const response = await axios.get(`https://algolia-gateway.netlify.app/api/locations/search`, {
      params: {
        ...LocationService.params,
        query,
      },
    })
    return response.data.hits || []
  }

  static searchLocationsViaCoordinates = async (lat, lng) => {
    const response = await axios.get(`https://algolia-gateway.netlify.app/api/locations/search`, {
      params: {
        ...LocationService.params,
        lat,
        lng,
      },
    })

    return response.data.hits || []
  }
}
