export default {
  A281: 'cdb01',
  A311: 'cdb02',
  A368: 'cdb03',
  A387: 'cdb04',
  A184: 'cdb05',
  A202: 'cdb06',
  A188: 'cdb07',
  A274: 'cdb08',
  A234: 'cdb09',
  A278: 'cdb10',
  A187: 'cdb11',
  A380: 'cdb12',
  A319: 'cdb13',
  A325: 'cdb14',
  A381: 'cdb15',
  A267: 'cdb16',
  A235: 'cdb17',
  A270: 'cdb18',
  A315: 'cdb19',
  A320: 'cdb20',
  A299: 'cdb21',
  A394: 'cdb22',
  A393: 'cdb23',
  A349: 'cdb24',
  A365: 'cdb25',
  A245: 'cdb26',
  A367: 'cdb27',
  A397: 'cdb28',
  A248: 'cdb29',
  A240: 'cdb30',
  A386: 'cdb31',
  A308: 'cdb32',
  A383: 'cdb33',
  A384: 'cdb34',
  A091: 'cdb35',
  A385: 'cdb36',
  A343: 'cdb37',
  A287: 'cdb38',
  A263: 'cdb39',
  A283: 'cdb40',
  A269: 'cdb41',
  A176: 'cdb42',
  A167: 'cdb43',
  A335: 'cdb44',
  A289: 'cdb45',
  A374: 'cdb46',
  A288: 'cdb47',
  A377: 'cdb48',
  A294: 'cdb49',
  A271: 'cdb50',
  A378: 'cdb51',
  A395: 'cdb52',
  A265: 'cdb53',
  A322: 'cdb54',
  A183: 'cdb55',
  A312: 'cdb56',
  A280: 'cdb57',
  A391: 'cdb58',
  A398: 'cdb59',
  A253: 'cdb60',
  A137: 'cdb61',
  A305: 'cdb62',
  A284: 'cdb63',
  A341: 'cdb64',
  A303: 'cdb65',
  A272: 'cdb66',
  A373: 'cdb67',
  A353: 'cdb68',
  A357: 'cdb69',
  A351: 'cdb70',
  A354: 'cdb71',
  A356: 'cdb72',
  A342: 'cdb73',
  A389: 'cdb74',
  A264: 'cdb75',
  A366: 'cdb76',
}
