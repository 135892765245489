<template>
  <div class="individual-location">
    <div class="individual-location__container">
      <div class="individual-location__container__type">
        {{ location.locationType || null }}
      </div>
      <div class="individual-location__container__title">
        <div class="individual-location__container__title__name">
          {{ location.name }}
        </div>
        <div
          v-if="formattedGeodistance"
          class="individual-location__container__title__distance"
          v-html="`<nobr>${formattedGeodistance}</nobr>`"
        ></div>
      </div>
      <!-- <div class="individual-location__container__company">
          {{ location.companyName }}
        </div> -->
      <div v-for="(addressLine, i) in location.formatted_address" :key="i">
        {{ addressLine }}
      </div>
      <v-btn
        class="individual-location__container__button"
        :style="{ borderRadius: '4px' }"
        color="#0D4037"
        :href="href"
      >
        <span :style="{ color: 'white' }">
          <v-icon size="x-large" class="individual-location__container__button__icon">
            {{ icons.mdiCheckBold }}
          </v-icon>
          Sélectionnez & jouez !
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mdiCheckBold } from '@mdi/js'
import mapping from '../assets/mapping'

export default {
  name: 'LocationCard',
  data: () => ({
    icons: {
      mdiCheckBold,
    },
  }),
  props: {
    location: {},
  },
  computed: {
    formattedGeodistance() {
      const distance = this.location._rankingInfo.geoDistance
      if (distance < 1000) {
        return `${distance} m`
      }
      return `${(distance / 1000).toFixed(1)} km`
    },
    href() {
      return `https://avis-comptoirsdelabio.gtr.cx/${mapping[this.location.external_id]}`
    },
  },
}
</script>

<style lang="scss" scoped>
.individual-location {
  width: 100%;
  &__container {
    &__title {
      display: flex;
      justify-content: space-between;

      &__name {
        font-weight: 600;
      }

      &__distance {
        color: #858484;
        padding-left: 10px;
      }
    }
    &__company {
      font-size: 1.1rem;
      font-weight: 411;
    }
    &__hours-dropdown {
      display: flex;
      padding: 0;
    }
    &__button {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      border-top-right-radius: 0 !important;
      border-top-left-radius: 0 !important;

      &__icon {
        margin-right: 1rem;
      }
    }
  }
}
</style>
