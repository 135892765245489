import axios from 'axios'

export default class GeosearchService {
  static getUserPosition = async () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        timeout: 10 * 1000,
      })
    })
  }

  static getUserPositionViaIP = async () => {
    try {
      const { data } = await axios.get('https://api.ipdata.co', {
        params: {
          'api-key': process.env.VUE_APP_IP_DATA_API_KEY,
        },
        headers: { Accept: 'application/json' },
      })
      const { latitude, longitude } = data
      return {
        latitude,
        longitude,
      }
    } catch {
      return null
    }
  }
}
