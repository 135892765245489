<template>
  <div class="home">
    <section class="home--section-right pa-4 pa-sm-6 pa-lg-8">
      <div class="home__container">
        <header class="home__container__header">
          <div class="home__container__header__left">
            <a :href="bannerUrl" target="_blank">
              <img
                :src="require('@/assets/images/banner.jpg')"
                alt="Donnez votre avis et tentez de gagner un barbecue électrique weber"
              />
            </a>
          </div>
        </header>

        <div v-if="!selectedLocation" class="home__container__main__locationPicker">
          <location-picker v-on:location="onLocationSelection" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import LocationPicker from '../components/LocationPicker.vue'

export default {
  name: 'Home',
  components: {
    LocationPicker,
  },
  data: vm => ({
    currentLanguage: vm.$i18n.locale,
    selectedLocation: null,
    bannerUrl: 'https://www.lescomptoirsdelabio.fr/',
    displayText: true,
  }),
  metaInfo: () => ({
    title: 'Jeu les comptoirs de la bio',
    meta: [{ name: 'description', content: 'Donnez votre avis et tentez de gagner un barbecue électrique weber' }],
  }),
  computed: {
    selectedLocationDisplay() {
      return `Vous avez sélctionné le magasin <strong>${this.selectedLocation.brand}</strong> à <strong>${this.selectedLocation.city.name}</strong>`
    },
  },
  methods: {
    onChange(locale) {
      this.$i18n.locale = locale
    },
    onLocationSelection(event) {
      this.displayText = false
      this.selectedLocation = event
    },
    onLocationChange() {
      this.selectedLocation = null
    },
  },
}
</script>

<style lang="scss">
.home {
  display: flex;
  min-height: 100%;

  &__link {
    max-width: 100%;
  }

  &--section-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $color-bg;
    width: 100%;

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      width: 600px;
      margin: 0 auto;
    }

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      width: 900px;
      margin: 0 auto;
    }

    @media (min-width: map-get($grid-breakpoints, 'xl')) {
      width: 1100px;
      margin: 0 auto;
    }

    @media (min-width: map-get($grid-breakpoints, 'xxl')) {
      width: 1300px;
      margin: 0 auto;
    }
  }

  &__logo {
    position: absolute;
    bottom: $gutter-tablet;
    left: $gutter-tablet;

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      bottom: $gutter-desktop;
      left: $gutter-desktop;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    width: 100%;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      max-width: 550px;
    }

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      max-width: 720px;
    }

    &__header {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 32px;

      &__left,
      &__center,
      &__right {
        display: flex;
        position: relative;
        justify-content: center;

        a {
          margin: -20px -20px 16px -20px;

          display: inline-flex;

          img {
            max-width: 100%;
            @media (min-width: map-get($grid-breakpoints, 'sm')) {
              width: 100%;
              display: block;
              flex: 0 0 auto;
            }
          }
        }
      }

      &__center {
        &:before {
          position: absolute;
          top: 50%;
          left: -16px;
          transform: translateY(-50%);
          background-color: rgba($color-text-main, 0.8);
          width: 2px;
          height: 70%;
          content: '';
        }
      }
    }

    &__main {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-transform: uppercase;

      &__intro {
        text-transform: initial;
        font-size: 1.375rem;
      }

      &__title {
        color: $color-text-accent;
        font-size: 1.475rem;
        font-weight: bold;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 2rem;
        }
        &__accent {
          color: #0d4037;
          font-size: 1.875rem;
          @media (min-width: map-get($grid-breakpoints, 'sm')) {
            font-size: 2.5rem;
          }
        }
      }

      &__separator {
        position: relative;
        width: 100%;
        text-align: center;
        color: $color-text-main;
        font-size: 1.5rem;
        font-weight: bold;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 1.75rem;
        }

        &:before {
          position: absolute;
          top: 50%;
          right: 0;
          left: 0;
          transform: translateY(-50%);
          background-color: rgba($color-text-main, 0.7);
          height: 2px;
          content: '';
        }

        &__label {
          position: relative;
          background-color: $color-bg;
        }
      }

      &__subtitle {
        text-transform: uppercase;
        color: $color-text-accent;
        font-size: 1.7rem;
        font-weight: bold;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 2.25rem;
        }
      }

      &__caption {
        color: $color-text-main;
        font-size: 1.25rem;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 1.75rem;
        }
      }

      &__link {
        font-weight: bold;
      }
    }

    &__language {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__label {
        text-transform: uppercase;
        color: $color-text-main;
        font-size: 1.25rem;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 1.5rem;
        }
      }
    }
  }
}
</style>
