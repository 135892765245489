<template>
  <div class="location-picker">
    <div class="location-picker__search">
      <v-text-field
        v-model="query"
        clearable
        single-line
        type="text"
        loader-height="4"
        return-object
        no-filter
        color="primary"
        solo
        hide-no-data
        hide-details="auto"
        placeholder="Saisir une ville ou un code postal"
        :style="{ borderRadius: '4px' }"
        :loading="autocompleteLoading"
        :items="locationCandidates"
        :error-messages="errorMessages"
        @clear="clearSuggestedPlaces"
        @keyup.enter="onSearch"
      >
        <template v-slot:append>
          <v-icon @click="onSearch">
            {{ icons.mdiMagnify }}
          </v-icon>
        </template>
      </v-text-field>
    </div>

    <div :style="{ width: '100%', textAlign: 'center' }">
      <div :style="{ padding: '8px', visibility: geolocateFailVisibility }">La géolocalisation a échoué</div>

      <v-progress-circular
        v-if="geolocatingUserViaIP"
        indeterminate
        size="18"
        :style="{ width: '100%', margin: 'auto' }"
      />

      <v-btn
        v-if="!loadingLocations && !geolocatingUserViaIP"
        :style="{ margin: 'auto', marginBottom: '24px' }"
        @click="geolocateUser"
      >
        <v-progress-circular
          v-if="geolocatingUser"
          indeterminate
          size="18"
          :style="{ width: '100%', margin: 'auto' }"
        />
        <span v-else
          ><v-icon dense> {{ icons.mdiCrosshairsGps }} </v-icon>Me géolocaliser</span
        >
      </v-btn>
    </div>

    <div class="location-picker__list-container" fluid>
      <div v-if="loadingLocations">
        <v-skeleton-loader
          color="transparent"
          width="60%"
          height="40px"
          type="image"
          :loading="true"
          :style="{ marginBottom: '1rem' }"
        />
        <v-skeleton-loader
          v-for="index in 5"
          :key="index"
          type="image"
          color="transparent"
          height="130px"
          :loading="true"
          :style="{ marginBottom: '2.5rem' }"
        />
      </div>

      <div v-if="locationCandidates.length" class="location-picker__list-container__title">
        Sélectionnez votre magasin
      </div>
      <v-card
        v-for="(locationCandidate, i) in locationCandidates"
        :key="i"
        class="location-picker__list-container__card"
      >
        <v-list lines="two" density="comfortable" :style="{ paddingBottom: 0, paddingTop: 0 }">
          <v-list-item inactive class="location-picker__list-container__card__item" three-line :ripple="false">
            <location-card :location="locationCandidate" v-on:select="onLocationSelect" />
          </v-list-item>
        </v-list>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mdiCrosshairsGps, mdiMagnify } from '@mdi/js'
import LocationService from '../services/LocationService'
import GeosearchService from '../services/GeosearchService'
import LocationCard from './LocationCard.vue'

export default {
  name: 'LocationPicker',
  components: {
    LocationCard,
  },
  data: () => ({
    icons: {
      mdiCrosshairsGps,
      mdiMagnify,
    },
    query: '',
    loadingLocations: false,
    geolocatingUser: false,
    geolocatingUserViaIP: false,
    autocompleteLoading: false,
    displayNoResults: false,
    geolocateUserFailed: false,
    locationCandidates: [],
    errorMessages: null,
    userCoordinates: null,
  }),
  async mounted() {
    {
      await this.geolocateUserViaIP()
    }
  },
  computed: {
    displaySkeletonLoader() {
      return this.loadingLocations
    },
    geolocateFailVisibility() {
      return this.geolocateUserFailed && !this.loadingLocations ? 'visible' : 'hidden'
    },
  },
  methods: {
    async onSearch() {
      if (this.query.length < 3) return
      this.loadingLocations = true
      this.locationCandidates = await LocationService.searchLocations(this.query)
      this.loadingLocations = false
    },
    async geolocateUser() {
      this.geolocatingUser = true
      this.geolocateUserFailed = false
      try {
        const { coords } = await GeosearchService.getUserPosition()
        this.locationCandidates = await LocationService.searchLocationsViaCoordinates(coords.latitude, coords.longitude)
        this.geolocatingUser = false
      } catch (error) {
        this.geolocateUserFailed = true
        this.geolocatingUser = false
      }
    },
    async geolocateUserViaIP() {
      this.geolocatingUserViaIP = true
      this.geolocateUserFailed = false
      const userCoordinates = await GeosearchService.getUserPositionViaIP()
      if (!userCoordinates) {
        this.geolocatingUserViaIP = false
        return
      }
      this.locationCandidates = await LocationService.searchLocationsViaCoordinates(
        userCoordinates.latitude,
        userCoordinates.longitude
      )
      this.geolocatingUserViaIP = false
    },
    clearSuggestedPlaces() {
      this.locationCandidates = []
    },
    onLocationSelect(event) {
      this.$emit('location', event)
    },
  },
}
</script>

<style scoped lang="scss">
.location-picker {
  min-height: calc(350px + 3rem);
  padding: 8px;

  &__search {
    &__button {
      margin: auto 8px;
    }
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }

  &__header {
    display: flex;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      margin-bottom: 1rem;
    }
  }

  &__error {
    margin-bottom: 16px;
  }

  &__list-container {
    &__title {
      font-weight: bold;
      font-size: 1.125rem;
      margin: auto 0;
      padding-bottom: 1rem;
    }

    &__skeleton {
      max-height: 175px;
      margin: 1.25rem 0;
    }
    &__card {
      margin-bottom: 1.5rem;

      &:last-child {
        margin-bottom: 0px;
      }

      &__item {
        position: relative;
        padding-bottom: 2.5rem;
      }
    }
  }
}
</style>
